// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import Vue from 'vue/dist/vue.esm';
import VueRouter from 'vue-router/dist/vue-router.esm'
import VueI18n from 'vue-i18n'
import routes from '../routes'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from "axios"
import { mapState } from 'vuex'
import moment from 'moment'

import '../assets/css/bootstrap.scss'
import '../assets/css/linearicons.css'

import '../assets/css/custom.css'
import '../assets/css/settings.css'

import frTranslation from '../locales/fr'
import enTranslation from '../locales/en'

const messages = {
  en: enTranslation,
  fr: frTranslation
}

let axios_config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  withCredentials: true // Check cross-site Access-Control
};

const _axios = axios.create(axios_config)

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm')
  }
});

Vue.filter('to_inches', function(value) {
  if (value >= 0 && value != null) {
    return ((parseFloat(value)/2.54) || 0).toFixed(2)
  }
});

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(VueI18n)

library.add(faUserSecret)
Vue.component('font-awesome-icon', FontAwesomeIcon)

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: routes
})

import store from '../store'

router.beforeEach((to, from, next) => {
  store.dispatch("refreshData", to.path);
  next();
});

const i18n = new VueI18n({
  locale: store.getters.current_locale || "en", // set locale
  messages, // set locale messages
})

Vue.mixin({
  methods: {
    currentApiDomain: function(){
      return process.env.API_DOMAIN
    },

    convertMeterToKm: function(meters){
      if(parseInt(meters)>0){
        return `${Math.round((meters/1000)*10)/10}Km`
      }
    }
  },
  computed: {
    ...mapState([
      'current_user',
      'csrf',
      'preferences',
      'current_employee'
    ])
  }
})

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    router,
    i18n,
    store,
    mounted: function(){
      store.commit("set_i18n", this.$i18n)
    }
  }).$mount('#application');
});
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
