export default {
  search: "Search",
  settings: "settings",
  structure_capacity: "Structure capacity",
  fixed_data: "Fixed data",
  snow_data: "Snow data",
  users: "Users",
  clients: "Clients",
  client: "Client",
  province: "Province",
  region: "Region",
  ice: "Ice",
  wet_snow: "Wet snow",
  compacted_snow: "Compacted snow",
  compact_snow: "Compacted snow",
  light_snow: "Light snow",
  action: "Action",
  edit: "Edit",
  roof_structure_capacity: "Roof structure capacity",
  cancel: "Cancel",
  save: "Save",
  lb_sq_ft: "lb/sq.ft",
  type_of_snow: "type of snow",
  weight_per_inch_thickness: "weight per inch thickness",
  status_range_value: "Status range value",
  green_yellow_factor: "Green/yellow factor",
  yellow_red_factor: "Yellow/red factor",
  green_roof_structure_capacity:  "Green: Total weight is less or equal to {val}% of roof structure capacity",
  yellow_roof_structure_capacity:  "Yellow: Total weight is more than {val}% of roof structure capacity",
  red_roof_structure_capacity:  "Red: Total weight is more or equal to {val}% of roof structure capacity",
  organization: "Organization",
  client_name: "Client name",
  email: "Email",
  address: "Address",
  actions: "Actions",
  new_client: "New client",
  dashboard: "Dashboard",
  name_of_organization: "Name of organization",
  first_name: "First name",
  last_name: "Last name",
  allow_notification: "Allow Notification",
  name: "Name",
  role: "Role",
  active: "Active",
  admin: "Admin",
  user: "User",
  Admin: "Admin",
  User: "User",
  weather_station_km: "weather station (km)",
  snow_accumulation_cm: "snow accumulation (Inches)",
  estimated_weight: "Actual estimated weight",
  estd_weight_in_days: "Estimated weight in 4 days",
  status: "status",
  new_building: "new building",
  sign_out: "sign out",
  safe: "safe",
  alert: "caution",
  emergency: "emergency",
  default_value_is: "Default value is {val}",
  sign_in: "sign in",
  new_user: "New user",
  building: "Building",
  name_of_building: "Name of building",
  sum_of_snow_eq_100: "Sum of snow data data must be equal to 100",
  Safe: "safe",
  Alert: "caution",
  Emergency: "emergency",
  show: "Show",
  start_typing: "Start typing",
  language: "Language",
  last_weather_update: "Last weather update",
  on_ground_snow_accumulation: "On ground snow acculation (inches)",
  estd_on_roof_accumulation: "Estimated on roof snow accumulation (inches)",
  last_snow_removal_date: "Last snow removal date",
  did_you_do_snow_removal: "Did you do snow removal?",
  yes: "Yes",
  no: "No",
  inches: "Inches",
  cm: "cm.",
  buildings: "Buildings",
  project_manager: "Project manager",
  cant_be_blank: "can't be blank",
  reset_snow_data_manually_updated: "Do you want to reset snow data on buildings that are manually updated?",
  actual_height_of_snow: "Actual height of snow on roof"
}
