<template>
  <b-navbar class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <b-navbar-brand href="#" class="pt-0">
      <img src="../assets/images/logo.png" style="width:150px;" alt="logo">
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item :to="{ name: 'buildings'}" class="mr-3">
          {{ $t("dashboard") }}
        </b-nav-item>
      </b-navbar-nav>
      <div class="mr-3">
        <b-form-select v-model="locale" :options="langs"></b-form-select>
      </div>
      <b-dropdown id="dropdown-1" no-caret variant="primary">
        <template slot="button-content">
          <span class="lnr lnr-user"></span>
          {{current_user.first_name}} {{current_user.last_name}}
        </template>
        <b-dropdown-item>
          <router-link :to="{ name: 'settings_snow_data'}">
            {{ $t('settings') }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>
          <a href="/users/sign_out" data-method="delete">
            {{ $t('sign_out') }}
          </a>
        </b-dropdown-item>
      </b-dropdown>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  data: function () {
    return {
      langs: ["en", "fr"],
      locale: this.$i18n.locale
    }
  },

  watch: {
    locale: function(newVal, oldVal){
      // this.$i18n.locale = newVal
      this.$store.dispatch("refreshLocale", {locale: newVal, i18n: this.$i18n})
    }
  }
}
</script>
