<template>
  <div>
    <Navbar />
    <b-container fluid class="main" role="main">
      <b-row class="align-items-start insp-list">
        <SettingsSidebar selectedComponent="clients"/>
        <b-col md="10" class="offset-2">
          <b-card>
            <form class="mt-4">
						  <div class="form-row">
  							<div class="col-md-6 col-sm-6">
  							  <div class="search-box">
  									<span class="lnr lnr-magnifier"></span>
  									<a href="" class="close-srch"><span class="lnr lnr-cross"></span></a>
  									<input type="text" class="form-control" :placeholder="$t('search')" v-model="filter">
  							  </div>
  							</div>

  							<div class="col-md-2 col-sm-6 ml-auto">
                  <b-button variant="primary" @click="openNewFormModal" class="float-right">
                    + {{ $t('new_client') }}
                  </b-button>
  							</div>
						  </div>
						</form>
            <b-table :fields="fields" :items="data" head-variant="light" :filter="filter" responsive class="mt-4">
              <template slot="client_name" slot-scope="data">
                {{data.item.first_name}} {{data.item.last_name}}
              </template>

              <template slot="actions" slot-scope="data">
                <b-link class="badge badge-light" @click="editClient(data.item.id)">
                  <span class="lnr lnr-pencil"></span>
                  {{ $t('edit') }}
                </b-link>
                <b-link class="badge badge-light">
                  <span class="lnr lnr-trash"></span>
                </b-link>
              </template>
            </b-table>

            <b-modal id="modal-1" title="Client" @ok="submitForm" :cancelTitle="$t('cancel')">
              <b-form>
                <b-form-group
                  id="client-organization-group"
                  :label="$t('organization')"
                  label-for="client-organization"
                  :description="$t('name_of_organization')"
                >
                  <b-form-input
                    id="client-organization"
                    v-model="form_data.organization"
                    type="text"
                    required
                    :placeholder="$t('organization')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="client-first-name-group"
                  :label="$t('first_name')"
                  label-for="client-first-name"
                >
                  <b-form-input
                    id="client-first-name"
                    v-model="form_data.first_name"
                    type="text"
                    required
                    :placeholder="$t('first_name')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="client-last-name-group"
                  :label="$t('last_name')"
                  label-for="client-last-name"
                >
                  <b-form-input
                    id="client-last-name"
                    v-model="form_data.last_name"
                    type="text"
                    :placeholder="$t('last_name')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="client-email-group"
                  :label="$t('email')"
                  label-for="client-email"
                >
                  <b-form-input
                    id="client-email"
                    v-model="form_data.primary_email"
                    type="email"
                    required
                    :placeholder="$t('email')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="client-email-group"
                  label="Secondary email"
                  label-for="client-email"
                >
                  <b-form-input
                    id="client-email"
                    v-model="form_data.secondary_email"
                    type="email"
                    required
                    placeholder="Secondary email"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="client-address-group"
                  :label="$t('address')"
                  label-for="client-address"
                >
                  <b-form-input
                    id="client-address"
                    v-model="form_data.address"
                    type="text"
                    :placeholder="$t('address')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="client-province-group"
                  :label="$t('province')"
                  label-for="client-province"
                >
                  <b-form-input
                    id="client-address"
                    v-model="form_data.province"
                    type="text"
                    :placeholder="$t('province')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="client-language-group"
                  :label="$t('language')"
                  label-for="client-language"
                >
                  <b-form-select
                    id="building-client"
                    v-model="form_data.locale"
                    :options="locales"
                  ></b-form-select>
                </b-form-group>

                <b-form-checkbox
                  id="allow-notification"
                  v-model="form_data.allow_notification"
                  value="true"
                  unchecked-value="false"
                >
                  {{ $t('allow_notification') }}
                </b-form-checkbox>
              </b-form>
            </b-modal>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import Navbar from '../../../components/navbar.vue'
  import SettingsSidebar from '../../../components/sidebar.vue'

  export default{
    components: {
      Navbar,
      SettingsSidebar
    },

    data: function(){
      return {
        data: [],

        form_data: {},
        filter: null,
        locales: ["en", "fr"]
      }
    },

    mounted: function(){
      axios.get("/api/settings/clients.json")
      .then(response => {
        this.data = response.data
      })
    },

    methods: {
      submitForm: function(bvModalEvt){
        bvModalEvt.preventDefault()
        const vm = this
        if(this.form_data.id){
          axios.put("/api/settings/clients/"+this.form_data.id+".json", {
            client: this.form_data,
            authenticity_token: this.csrf
          })
          .then(response => {
            this.$set(vm.data, this.findIndexOfClient(this.form_data.id), response.data);
            vm.$bvModal.hide("modal-1")
          })
          .catch(error => {

          })
        }
        else{
          axios.post("/api/settings/clients.json", {
            client: this.form_data,
            authenticity_token: this.csrf
          })
          .then(response => {
            this.data.push(response.data)
            vm.$bvModal.hide("modal-1")
          })
          .catch(error => {

          })
        }
      },

      findIndexOfClient: function(client_id){
        return this.data.findIndex( u => u.id == client_id )
      },

      editClient: function(client_id){
        var item = this.data[this.findIndexOfClient(client_id)] || this.form_data
        this.form_data = Object.assign({}, (this.data[this.findIndexOfClient(client_id)] || this.form_data))
        this.$bvModal.show("modal-1")
      },

      resetModelInfo: function(){
        this.form_data = {
          id: null,
          organization: "",
          email: "",
          address: "",
          city: "",
          province: "",
          country: "",
          locale: ""
        }
      },

      openNewFormModal: function(){
        this.resetModelInfo();
        this.$bvModal.show("modal-1")
      },

      destroyClient: function(client_id){
        axios.delete("/api/settings/clients/"+client_id+".json?authenticity_token="+this.csrf)
        .then(response => {
          this.data.splice(this.findIndexOfClient(client_id), 1)
        })
        .catch(error => {

        })
      }
    },

    computed: {
      fields: function(){
        return [
          {
            key: "organization",
            label: this.$t('organization')
          },
          {
            key: "client_name",
            label: this.$t('client_name')
          },
          {
            key: "primary_email",
            label: this.$t('email')
          },
          {
            key: "address",
            label: this.$t('address')
          },
          {
            key: "actions",
            label: this.$t('actions')
          }
        ]
      }
    }
  }
</script>
