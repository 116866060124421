<template>
  <div>
    <Navbar />
    <b-container fluid class="main" role="main">
      <div class="row align-items-start insp-list">
        <SettingsSidebar selectedComponent="buildings"/>
			  <div class="col-md-10 mb-3 offset-2">
  				<div class="card">
  				  <div class="card-body">
    					<form>
    					  <div class="form-row">
    						<div class="col-md-6 col-sm-6">
    						  <div class="search-box">
    								<span class="lnr lnr-magnifier"></span>
    								<a href="" class="close-srch"><span class="lnr lnr-cross"></span></a>
    								<input type="text" class="form-control" :placeholder="$t('search')" v-model="filter">
    						  </div>
    						</div>
    						<!-- <div class="col-md-2 col-sm-6">
    						  <select id="inputState" class="form-control" v-model="province_filter">
      							<option value="" selected>Province</option>
                    <option v-for="(province, province_key) in provinces" :value="province_key">{{province.name}}</option>
    						  </select>
    						</div>
    						<div class="col-md-2 col-sm-6">
      						<select id="inputState" class="form-control" v-model="region_filter">
                    <option value="" selected>Region</option>
                    <template v-for="(province, province_key) in provinces" :value="province_key">
                      <option v-for="region in province.regions" :value="region">
                        {{region}}
                      </option>
                    </template>
                  </select>
    						</div> -->
    					  </div>
    					</form>

    					<b-table :fields="fields" :items="tableItems" :filter="filter" head-variant="light" responsive class="mt-4" @filtered="onFiltered">
                <template slot="client_organization" slot-scope="data">
                  {{ (data.item.client || {}).organization }}
                </template>

                <template slot="weather_station_distance" slot-scope="data">
                  {{convertMeterToKm(data.item.weather_station_distance)}}
                </template>

                <template slot="last_weather_update" slot-scope="data">
                  {{ data.item.last_weather_update | formatDate}}
                </template>

                <template slot="follow" slot-scope="data">
                  <b-form-checkbox
                    :id="'allow-notification-'+data.item.id"
                    v-model="data.item.follow"
                    value="true"
                    unchecked-value="false"
                    @change="updateBuilding(data.item.id)"
                  >
                  </b-form-checkbox>
                </template>

                <template slot="status" slot-scope="data">
                  <template v-if="data.item.follow">
                    <div class="rounded-circle bg-success p-1 sq-20">
                    </div>
                  </template>
                  <template v-else>
                    <div class="rounded-circle bg-danger p-1 sq-20">
                    </div>
                  </template>
                </template>
              </b-table>

  				  </div>
  				</div>
			  </div><!--12 end-->
		  </div>
    </b-container>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Navbar from '../../../components/navbar.vue'
import Provinces from '../../../provinces.js'
import SettingsSidebar from '../../../components/sidebar.vue'
export default {
  components: { Navbar, VueGoogleAutocomplete, SettingsSidebar },

  data: function () {
    return {
      data: [],
      form_data: {},
      address: '',
      regions: [],
      clients: [],
      users: [],
      provinces: Provinces,
      filter: null,
      snow_data_error: "",
      form_data_errors: {},
      province_filter: "",
      region_filter: "",
      status_filter: null
    }
  },

  mounted: function(){
    axios.get("/api/buildings.json")
    .then(response => {
      this.data = response.data
    })
    axios.get("/api/settings/clients.json")
    .then(response => {
      this.clients = response.data
    })
    axios.get("/api/settings/users.json")
    .then(response => {
      this.users = response.data
    })
  },

  methods: {
    findIndexOfBuilding: function(building_id){
      return this.data.findIndex( u => u.id == building_id )
    },

    updateBuilding: function(building_id){
      var building = this.data[this.findIndexOfBuilding(building_id)]
      var follow = !building.follow
      this.submitBuildingForm(building.id, {follow: follow})
    },

    submitBuildingForm: function(building_id, formdata={}){
      const vm = this
      axios.put("/api/buildings/"+building_id+".json", {
        building: formdata,
        authenticity_token: this.csrf
      })
      .then(response => {
        this.$set(vm.data, this.findIndexOfBuilding(building_id), response.data);
      })
      .catch(error => {
        this.form_data_errors = error.response.data
      })
    }
  },

  computed: {
    buildingManager: {
      get: function(){
        var manager_id = (this.form_data.manager_id || this.current_user.id)
        this.form_data.manager_id = manager_id
        return this.form_data.manager_id
      },
      set: function(newValue){
        this.form_data.manager_id = newValue
      }
    },

    selectableClients: function(){
      var selectable_clients = []
      this.clients.forEach(function(cl){
        selectable_clients.push({value: cl.id, text: cl.organization})
      })
      return selectable_clients
    },

    selectableUsers: function(){
      var selectable_users = []
      this.users.forEach(function(user){
        selectable_users.push({value: user.id, text: `${user.first_name} ${user.last_name}`})
      })
      return selectable_users
    },

    snowDataSum: function(){
      return (parseInt(this.form_data.ice) + parseInt(this.form_data.compact_snow) + parseInt(this.form_data.light_snow) + parseInt(this.form_data.wet_snow))
    },

    tableItems: function(){
      var items = []
      var vm = this
      if((this.status_filter || "").length > 0){
        this.data.forEach(function(val){
          if(val.status == vm.status_filter){
            items.push(val)
          }
        })
      }
      else{
        items = this.data
      }

      return items
    },

    fields: function(){
      return [
        {
         key: "client_organization",
         label: this.$t("client"),
        },
        {
         key: "name",
         label: this.$t("building"),
        },
        {
         key: "address",
         label: this.$t("address"),
        },
        {
         key: "region",
         label: this.$t("region"),
        },
        {
         key: "follow",
         label: "Follow"
       },
       {
        key: "status",
        label: "Status"
       }
      ]
    }
  }
}
</script>
<style scoped>
  .sq-20 {
    width: 20px;
    height: 20px
  }
</style>
