export default {
  search: "Rechercher",
  settings: "Paramètres",
  structure_capacity: "Capacité de structure",
  fixed_data: "Données fixes",
  snow_data: "Données sur la neige",
  users: "Utilisateurs",
  clients: "Clients",
  client: "Client",
  province: "Province",
  region: "Région",
  ice: "Glace",
  wet_snow: "Neige mouillée",
  compacted_snow: "Neige compactée",
  compact_snow: "Neige compactée",
  light_snow: "Neige légère",
  action: "Action",
  edit: "Éditer",
  roof_structure_capacity: "Capacité de la structure du toit",
  cancel: "Annuler",
  save: "Sauvegarder",
  lb_sq_ft: "lb/pi.ca",
  type_of_snow: "Type de neige",
  weight_per_inch_thickness: "Poids par pouce d'épaisseur",
  status_range_value: "Valeur de la plage de statut",
  green_yellow_factor: "Facteur vert/jaune",
  yellow_red_factor: "Facteur rouge",
  green_roof_structure_capacity:  "Vert: Le poids total est inférieur ou égal à {val} % de la capacité de la structure du toit",
  yellow_roof_structure_capacity:  "Jaune: Le poids total représente plus de {val} % de la capacité de la structure du toit",
  red_roof_structure_capacity:  "Rouge: Le poids total est supérieur ou égal à {val} % de la capacité de la structure du toit",
  organization: "Organisation",
  client_name: "Nom du client",
  email: "Courriel",
  address: "Adresse",
  actions: "Actions",
  new_client: "Nouveau client",
  dashboard: "Tableau de bord",
  name_of_organization: "Nom de l'organisation",
  first_name: "Prénom",
  last_name: "Nom de famille",
  allow_notification: "Actif",
  name: "Nom",
  role: "Rôle",
  active: "Actif",
  admin: "Tâches",
  user: "Utilisateur",
  Admin: "Tâches",
  User: "Utilisateur",
  weather_station_km: "Station météorologique (km)",
  snow_accumulation_cm: "Accumulation de neige (Inches)",
  estimated_weight: "Réel poids estimé",
  estd_weight_in_days: "Estimated weight in 4 days",
  status: "Statut",
  new_building: "Nouveau bâtiment",
  sign_out: "se déconnecter",
  safe: "Sans danger",
  alert: "Prudence",
  emergency: "Urgence",
  default_value_is: "La valeur par défaut est %{val}",
  sign_in: "Se connecter",
  new_user: "Nouveau Utilisateur",
  building: "Bâtiment",
  name_of_building: "Nom du bâtiment",
  sum_of_snow_eq_100: "la somme des pourcentage doit être êgalement à 100",
  Safe: "Sans danger",
  Alert: "Prudence",
  Emergency: "Urgence",
  show: "Montrer",
  start_typing: "Commencer à écrire",
  language: "Langue",
  last_weather_update: "Dernière mise-à-jour météo",
  on_ground_snow_accumulation: "Accumulation de neige au sol (pouces)",
  estd_on_roof_accumulation: "Accumulation approximative de neige au toit (pouces)",
  last_snow_removal_date: "Date du dernier enlèvement de neige",
  did_you_do_snow_removal: "Est-ce que la toitre à été déneigée?",
  yes: "Oui",
  no: "Non",
  inches: "Pouce",
  cm: "cm.",
  buildings: "Bâtiment",
  project_manager: "Chargé de projet",
  cant_be_blank: "Ne peut être vide",
  reset_snow_data_manually_updated: "Voulez-vous appliquer ces changements sur les bâtiments mis-à-jour manuellement?",
  actual_height_of_snow: "Épaisseur actuelle de la neige sur la toiture"
}
