<template>
  <div>
    <Navbar />
    <b-container fluid class="main" role="main">
      <b-row class="align-items-start insp-list">
        <SettingsSidebar selectedComponent="clients" itemType="clients"/>
        <b-col md="10" class="offset-2">
          <b-card>
            <form class="mt-4">
						  <div class="form-row">
  							<div class="col-md-6 col-sm-6">
  							  <div class="search-box">
  									<span class="lnr lnr-magnifier"></span>
  									<a href="" class="close-srch"><span class="lnr lnr-cross"></span></a>
  									<input type="text" class="form-control" placeholder="Search">
  							  </div>
  							</div>
  							<div class="col-md-2 col-sm-6 ml-auto">
                  <b-button variant="primary" @click="openNewFormModal" class="float-right">
                    + New Employee
                  </b-button>
  							</div>
						  </div>
						</form>
            <b-table :fields="fields" :items="data" head-variant="light" responsive class="mt-4">
              <template slot="first_name" slot-scope="data">
                {{(data.item.employee || {}).first_name}}
              </template>

              <template slot="last_name" slot-scope="data">
                {{(data.item.employee || {}).last_name}}
              </template>

              <template slot="actions" slot-scope="data">
                <b-link class="badge badge-light" @click="editClient(data.item.id)">
                  <span class="lnr lnr-pencil"></span>
                  Edit
                </b-link>
                <b-link class="badge badge-light">
                  <span class="lnr lnr-trash"></span>
                </b-link>
              </template>
            </b-table>

            <b-modal id="modal-1" title="Employee" @ok="submitForm" :cancelTitle="$t('cancel')">
              <b-form>
                <b-form-group
                  id="client-email-group"
                  label="Email"
                  label-for="client-email"
                >
                  <b-form-input
                    id="client-email"
                    v-model="form_data.email"
                    type="email"
                    required
                    placeholder="Email"
                  ></b-form-input>
                </b-form-group>
              </b-form>
            </b-modal>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import Navbar from '../../../../components/client_navbar.vue'
  import SettingsSidebar from '../../../../components/sidebar.vue'

  export default{
    components: {
      Navbar,
      SettingsSidebar
    },

    data: function(){
      return {
        data: [],

        form_data: {}
      }
    },

    mounted: function(){
      axios.get("/api/clients/"+this.$route.params.client_id+"/client_invitations.json")
      .then(response => {
        this.data = response.data
      })
    },

    methods: {
      submitForm: function(bvModalEvt){
        bvModalEvt.preventDefault()
        const vm = this
        if(this.form_data.id){
          axios.put("/api/clients/"+this.$route.params.client_id+"/client_invitations/"+this.form_data.id+".json", {
            client_invitation: this.form_data,
            authenticity_token: this.csrf
          })
          .then(response => {
            this.$set(vm.data, this.findIndexOfClient(this.form_data.id), response.data);
            vm.$bvModal.hide("modal-1")
          })
          .catch(error => {

          })
        }
        else{
          axios.post("/api/clients/"+this.$route.params.client_id+"/client_invitations.json", {
            client_invitation: this.form_data,
            authenticity_token: this.csrf
          })
          .then(response => {
            this.data.push(response.data)
            vm.$bvModal.hide("modal-1")
          })
          .catch(error => {

          })
        }
      },

      findIndexOfClient: function(client_id){
        return this.data.findIndex( u => u.id == client_id )
      },

      editClient: function(client_id){
        var item = this.data[this.findIndexOfClient(client_id)] || this.form_data
        this.form_data = Object.assign({}, (this.data[this.findIndexOfClient(client_id)] || this.form_data))
        this.$bvModal.show("modal-1")
      },

      resetModelInfo: function(){
        this.form_data = {
          id: null,
          email: ""
        }
      },

      openNewFormModal: function(){
        this.resetModelInfo();
        this.$bvModal.show("modal-1")
      },

      destroyClient: function(client_id){
        axios.delete("/api/clients/"+this.$route.params.client_id+"/client_invitations/"+client_id+".json?authenticity_token="+this.csrf)
        .then(response => {
          this.data.splice(this.findIndexOfClient(client_id), 1)
        })
        .catch(error => {

        })
      }
    },

    computed: {
      fields: function(){
        return [
          {
            key: "first_name",
            label: this.$t('first_name')
          },
          {
            key: "last_name",
            label: this.$t('last_name')
          },
          {
            key: "email",
            label: this.$t('email')
          },
          {
            key: "organization",
            label: this.$t('organization')
          },
          {
            key: "actions",
            label: this.$t('actions')
          }
        ]
      }
    }
  }
</script>
