<template>
  <nav class="col-md-2 d-none d-md-block bg-light sidebar mt-3">
    <div class="sidebar-sticky">
      <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>{{ $t('settings') }}</span>
        <a class="d-flex align-items-center text-muted" href="#">
        </a>
      </h6>
      <ul class="nav flex-column">
        <li v-for="(item, index) in computedItems" class="nav-item">
          <router-link :to="{ name: item.link}" class="nav-link" v-bind:class="selectedComponent==item.id ? 'active' : ''">
            {{item.label}}
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  props: ['selectedComponent', 'itemType'],

  data: function () {
    return {
    }
  },
  computed: {
    computedItems: function(){
      if(this.itemType == 'clients'){
        return this.clientItems
      }
      else{
        return this.items
      }
    },

    items: function(){
      return [
        {
          id: "structure_capacity",
          label: this.$t("structure_capacity"),
          link: "settings_structure_capacity"
        },
        {
          id: "fixed_data",
          label: this.$t("fixed_data"),
          link: "settings_fixed_data"
        },
        {
          id: "snow_data",
          label: this.$t("snow_data"),
          link: "settings_snow_data"
        },
        {
          id: "users",
          label: this.$t("users"),
          link: "settings_users"
        },
        {
          id: "clients",
          label: this.$t("clients"),
          link: "settings_clients"
        },
        {
          id: "buildings",
          label: this.$t("buildings"),
          link: "settings_buildings"
        }
      ]
    },

    clientItems: function(){
      return [
        {
          id: "employees",
          label: this.$t("employees"),
          link: "clients_employees"
        }
      ]
    }
  }
}
</script>
