<template>
  <div>
    <Navbar />
    <b-container fluid class="main" role="main">
      <b-row class="align-items-start insp-list">
        <SettingsSidebar selectedComponent="snow_data"/>
        <b-col md="10" class="offset-2">
          <b-card>
            <form>
  					  <div class="form-row justify-content-between">
    						<div class="col-md-6 col-sm-6">
    						  <div class="search-box">
    								<span class="lnr lnr-magnifier"></span>
    								<a href="" class="close-srch"><span class="lnr lnr-cross"></span></a>
    								<input type="text" class="form-control" :placeholder="$t('search')" v-model="filter">
    						  </div>
    						</div>
    						<!-- <div class="col-md-2 col-sm-6">
    						  <select id="inputState" class="form-control">
    							<option selected="">Show by</option>
    							<option>By Province</option>
    							<option>By Building</option>
    						  </select>
    						</div> -->
  					  </div>
  					</form>
            <b-table :fields="fields" :items="items" head-variant="light" responsive class="mt-4" :busy.sync="isBusy" :filter="filter">
              <template slot="ice" slot-scope="data">
                {{data.item.ice}}%
              </template>

              <template slot="wet_snow" slot-scope="data">
                {{data.item.wet_snow}}%
              </template>

              <template slot="compact_snow" slot-scope="data">
                {{data.item.compact_snow}}%
              </template>

              <template slot="light_snow" slot-scope="data">
                {{data.item.light_snow}}%
              </template>

              <template slot="action" slot-scope="data">
                <b-link class="badge badge-light" @click="editSnowData(data.item.id)">
                  <span class="lnr lnr-pencil"></span>
                  {{ $t('edit') }}
                </b-link>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-modal id="modal-1" title="Type of snow(%)" @ok='submitForm' :cancelTitle="$t('cancel')">
        <table class="table table-sm modal-table">
          <tbody>
            <tr v-if="errorMessage.length > 0">
              <td colspan="2">
                <b-badge variant="danger">
                  {{errorMessage}}
                </b-badge>
              </td>
            </tr>
            <tr>
              <td width="50%">
                {{ $t("ice") }}
              </td>
              <td>
                <form>
                  <div class="form-group rfc mb-0">
                    <input type="number" class="form-control form-control-sm" v-model="form_data.ice"> %
                  </div>
                </form>
              </td>
            </tr>
            <tr>
              <td width="50%">
                {{ $t('wet_snow') }}
              </td>
              <td>
                <form>
                  <div class="form-group rfc mb-0">
                  <input type="number" class="form-control form-control-sm" v-model="form_data.wet_snow"> %
                  </div>
                </form>
              </td>
            </tr>
            <tr>
              <td width="50%">
                {{ $t('compact_snow') }}
              </td>
              <td>
                <form>
                  <div class="form-group rfc mb-0">
                  <input type="number" class="form-control form-control-sm" v-model="form_data.compact_snow"> %
                  </div>
                </form>
              </td>
            </tr>
            <tr>
              <td width="50%">
                {{ $t('light_snow') }}
              </td>
              <td>
                <form>
                  <div class="form-group rfc mb-0">
                  <input type="number" class="form-control form-control-sm" v-model="form_data.light_snow"> %
                  </div>
                </form>
              </td>
            </tr>
            <tr>
              <td width="50%">
                Do you want to reset snow data on building that are manually updated?
              </td>
              <td>
                <form>
                  <div class="form-group rfc mb-0">
                    <input type="checkbox" class="form-control form-control-sm" v-model="form_data.update_data_in_manually_snow_updated_building" value="true"> 
                  </div>
                </form>
              </td>
            </tr>
          </tbody>
        </table>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
  import Navbar from '../../../components/navbar.vue'
  import SettingsSidebar from '../../../components/sidebar.vue'
  import Provinces from '../../../provinces.js'

  export default{
    components: {
      Navbar,
      SettingsSidebar
    },

    data(){
      return {
        isBusy: true,

        items: [],

        form_data: {
          id: null,
          region: "",
          province: "",
          province_code: "",
          ice: null,
          wet_snow: null,
          compact_snow: null,
          light_snow: null
        },
        filter: null,
        errorMessage: ""
      }
    },

    mounted: function(){
      axios.get("/api/settings/snow_types.json")
      .then(response => {
        this.isBusy = false
        this.items = response.data
      })
    },

    methods: {
      submitForm: function(bvModalEvt){
        bvModalEvt.preventDefault()
        const vm = this
        if(this.snowDataSum == 100){
          axios.put("/api/settings/snow_types/"+this.form_data.id+".json", {
            snow_data: this.form_data,
            authenticity_token: this.csrf
          })
          .then(response => {
            this.$set(vm.items, this.findIndexOfSnowData(this.form_data.id), response.data);
            vm.$bvModal.hide("modal-1")
          })
          .catch(error => {
            this.errorMessage = (error.response.data.base || []).join(", ")
          })
        }
        else{
          this.errorMessage = this.$t('sum_of_snow_eq_100')
        }
      },

      findIndexOfSnowData: function(snow_data_id){
        return this.items.findIndex( d => d.id == snow_data_id )
      },

      editSnowData: function(snow_data_id){
        this.errorMessage = ""
        var item = this.items[this.findIndexOfSnowData(snow_data_id)] || this.form_data
        this.form_data = Object.assign({}, item)
        this.$bvModal.show("modal-1")
      },

      resetFormData: function(){
        this.form_data = {
          id: null,
          region: "",
          province: "",
          province_code: "",
          ice: null,
          wet_snow: null,
          compact_snow: null,
          light_snow: null
        }
      }
    },

    computed: {
      snowDataSum: function(){
        return (parseInt(this.form_data.ice) + parseInt(this.form_data.compact_snow) + parseInt(this.form_data.light_snow) + parseInt(this.form_data.wet_snow))
      },

      fields: function(){
        return [
          {
            key: "province",
            label: this.$t('province')
          },
          {
            key: "region",
            label: this.$t('region')
          },
          {
            key: "ice",
            label: this.$t('ice')
          },
          {
            key: "wet_snow",
            label: this.$t('wet_snow')
          },
          {
            key: "compact_snow",
            label: this.$t('compact_snow')
          },
          {
            key: "light_snow",
            label: this.$t('light_snow')
          },
          {
            key: "action",
            label: this.$t('action')
          }
        ]
      }
    }
  }
</script>
