export default {
  "ON": {
    "name": "Ontario",
    "regions": [
      "Montérégie",
      "Estrie",
      "Montreal",
      "Laval",
      "Centre-du-Québec",
      "Chaudière-Appalaches",
      "Outaouais",
      "Laurentides",
      "Lanaudière",
      "Mauricie",
      "Capitale-Nationale",
      "Bas-Saint-Laurent",
      "Gaspésie–Îles-de-la-Madeleine",
      "Abitibi-Témiscamingue",
      "Saguenay–Lac-Saint-Jean",
      "Côte-Nord",
      "Nord-du-Québec"
    ]
  },
  "QC": {
    "name": "Quebec",
    "regions": [
      "Bas-Saint-Laurent",
      "Saguenay–Lac-Saint-Jean",
      "Capitale-Nationale",
      "Mauricie",
      "Estrie",
      "Montréal",
      "Outaouais",
      "Abitibi-Témiscamingue",
      "Côte-Nord",
      "Nord-du-Québec",
      "Gaspésie–Îles-de-la-Madeleine",
      "Chaudière-Appalaches",
      "Laval",
      "Lanaudière",
      "Laurentides",
      "Montérégie",
      "Centre-du-Québec"
    ]
  },
  "NS": {
    "name": "Nova Scotia"
  },
  "NB": {
    "name": "New Brunswick"
  },
  "MB": {
    "name": "Manitoba"
  },
  "BC": {
    "name": "British Columbia"
  },
  "PE": {
    "name": "Prince Edward Island"
  },
  "SK": {
    "name": "Saskatchewan"
  },
  "AB": {
    "name": "Alberta"
  },
  "NL": {
    "name": "Newfoundland and Labrador"
  },
}
