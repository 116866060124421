import BuildingIndex from './views/buildings/index.vue'
import SettingsSnowDataIndex from './views/settings/snow_data/index.vue'
import SettingsFixedDataIndex from './views/settings/fixed_data/index.vue'
import SettingsStructureCapacityIndex from './views/settings/structure_capacity/index.vue'
import SettingsUsersIndex from './views/settings/users/index.vue'
import SettingsClientsIndex from './views/settings/clients/index.vue'
import ClientsBuildingIndex from './views/clients/buildings/index.vue'
import ClientsEmployeesIndex from './views/clients/settings/employees/index.vue'
import SettingsBuildingsIndex from './views/settings/buildings/index.vue'

export default [
  { path: '/', name: 'root', component:  BuildingIndex },
  { path: '/buildings', name: 'buildings', component:  BuildingIndex },
  { path: '/settings/structure_capacity', name: 'settings_structure_capacity', component:  SettingsStructureCapacityIndex },
  { path: '/settings/fixed_data', name: 'settings_fixed_data', component:  SettingsFixedDataIndex },
  { path: '/settings/snow_data', name: 'settings_snow_data', component:  SettingsSnowDataIndex },
  { path: '/settings/users', name: 'settings_users', component:  SettingsUsersIndex },
  { path: '/settings/clients', name: 'settings_clients', component:  SettingsClientsIndex },
  { path: '/settings/buildings', name: 'settings_buildings', component:  SettingsBuildingsIndex },
  { path: '/clients/:client_id/buildings', name: 'clients_buildings', component: ClientsBuildingIndex },
  { path: '/clients/:client_id/employees', name: 'clients_employees', component: ClientsEmployeesIndex }
]
