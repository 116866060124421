<template>
  <div>
    <Navbar />
    <b-container fluid class="main" role="main">
      <b-row class="align-items-start insp-list">
        <SettingsSidebar selectedComponent="fixed_data"/>
        <b-col md="10" class="offset-2">
          <b-card>
            <b-row>
              <b-col md="6">
                <table class="table mt-4">
								  <thead class="thead-light">
  									<tr>
  									  <th scope="col">{{ $t('type_of_snow') }}</th>
                      <th> {{ $t('weight_per_inch_thickness')}} </th>
  									</tr>
  								  </thead>
								  <tbody>
  									<tr>
  									  <td>{{ $t('ice') }} : </td>
  									  <td>
  											<form>
  											  <div class="form-group rfc mb-0">
  												  {{density.ice}}
  											  </div>
  											</form>
  									  </td>
  									</tr>
  									<tr>
  									  <td>
                        {{ $t('wet_snow') }} :
                       </td>
  									  <td>
  											<form>
  											  <div class="form-group rfc mb-0">
  												  {{density.wet_snow}}
  											  </div>
  											</form>
  									  </td>
  									</tr>
  									<tr>
  									  <td>
                        {{ $t('compact_snow') }} :
                       </td>
  									  <td>
  											<form>
  											  <div class="form-group rfc mb-0">
  												  {{density.compact_snow}}
  											  </div>
  											</form>
  									  </td>
  									</tr>
  									<tr>
  									  <td>
                        {{ $t('light_snow') }} :
                      </td>
  									  <td>
  											<form>
  											  <div class="form-group rfc mb-0">
  												  {{density.light_snow}}
  											  </div>
  											</form>
  									  </td>
  									</tr>
								  </tbody>
								</table>
              </b-col>
              <b-col md="6">
                <div class="table-responsive">
  								<table class="table mt-4">
  								  <thead class="thead-light">
    									<tr>
    									  <th scope="col" colspan="2">
                          {{ $t('status_range_value') }}
                        </th>
    									</tr>
  								  </thead>
  								  <tbody>
    									<tr>
    									  <td>{{$t('status')}} {{ $t('green_yellow_factor') }}</td>
    									  <td>
    											<form>
    											  <div class="form-group rfc mb-0">
    												<input type="number" class="form-control form-control-sm" v-model="preferences.safe_factor"> %
    											  </div>
    											</form>
    									  </td>
    									</tr>
    									<tr>
    									  <td>
                          {{$t('status')}} {{ $t('yellow_red_factor') }}
                        </td>
    									  <td>
    											<form>
    											  <div class="form-group rfc mb-0">
    												<input type="number" class="form-control form-control-sm" v-model="preferences.warning_factor"> %
    											  </div>
    											</form>
    									  </td>
    									</tr>
      								<template v-if="errorMessages.length >0">
                        <tr v-for="message in errorMessages">
                          <td colspan="2" class="text-center">
                            <b-badge variant="danger"> {{message}}</b-badge>
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr>
      									  <td colspan="2">{{ $t('green_roof_structure_capacity', {val: preferences.safe_factor}) }}</td>
      									</tr>
      									<tr>
      									  <td colspan="2">
                            {{ $t('yellow_roof_structure_capacity', {val: preferences.safe_factor}) }}
                          </td>
      									</tr>
                        <tr>
      									  <td colspan="2">
                            {{ $t('red_roof_structure_capacity', {val: preferences.warning_factor}) }}
                          </td>
      									</tr>
                      </template>
  								  </tbody>
  								</table>
  						  </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="text-center">
                <button type="button" class="btn btn-light">{{ $t('cancel') }}</button>
						    <button type="button" class="btn btn-primary" @click="submitForm">&nbsp; {{ $t('save') }} &nbsp;</button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import Navbar from '../../../components/navbar.vue'
  import SettingsSidebar from '../../../components/sidebar.vue'

  export default{
    components: {
      Navbar,
      SettingsSidebar
    },

    data: function(){
      return {
        density: {
          ice: 4.5,
          wet_snow: 3,
          compact_snow: 2,
          light_snow: 1
        },
        preferences: {
          id: null,
          safe_factor: 0,
          warning_factor: 0,
          alert_factor: 0
        }
      }
    },

    mounted: function(){
      axios.get("/api/settings/preferences.json")
      .then(response => {
        this.preferences = response.data
      })
    },

    methods: {
      submitForm: function(){
        const vm = this
        this.preferences
        if(this.preferences.id){
          axios.put("/api/settings/preferences/"+this.preferences.id+".json", {
            preferences: this.preferences,
            authenticity_token: this.csrf
          })
          .then(response => {
            this.$set(vm.data, this.findIndexOfUser(this.preferences.id), response.data);
          })
          .catch(error => {

          })
        }
        else{
          axios.post("/api/settings/preferences.json", {
            preferences: this.preferences,
            authenticity_token: this.csrf
          })
          .then(response => {
            this.preferences = response.data
          })
          .catch(error => {

          })
        }
      }
    },

    computed: {
      alertFactor: function(){
        return (100 - parseInt(this.preferences.warning_factor))
      },
      isValid: function(){
        return (parseInt(this.preferences.safe_factor) < parseInt(this.preferences.warning_factor))
      },

      errorMessages: function(){
        var errors = []
        if(!this.isValid){
          errors.push("Green factor must be less then yellow factor")
        }
        if(this.alertFactor < 0){
          errors.push("yellow factor or Green factor can not be more then 100")
        }
        return errors
      }
    },

    watch: {
      alertFactor: function(newVal, oldVal){
        this.preferences.alert_factor = newVal
      }
    }
  }
</script>
