<template>
  <div>
    <Navbar />
    <b-container fluid class="main" role="main">
      <b-row class="align-items-start insp-list">
        <SettingsSidebar selectedComponent="users"/>
        <b-col md="10" class="offset-2">
          <b-card>
            <div class="mt-4">
						  <div class="form-row">
  							<div class="col-md-6 col-sm-6">
  							  <div class="search-box">
  									<span class="lnr lnr-magnifier"></span>
  									<a href="" class="close-srch"><span class="lnr lnr-cross"></span></a>
  									<input type="text" class="form-control" :placeholder="$t('search')" v-model="filter">
  							  </div>
  							</div>
  							<div class="col-md-2 col-sm-6 ml-auto">
                  <b-button variant="primary" @click="openNewFormModal" class="float-right">
                    + {{ $t('new_user') }}
                  </b-button>
  							</div>
						  </div>
						</div>
            <b-table :fields="fields" :items="data" head-variant="light" responsive class="mt-4" :filter="filter">
              <template slot="name" slot-scope="data">
                {{data.item.first_name}} {{data.item.last_name}}
              </template>

              <template slot="role" slot-scope="data">
                {{$t(data.item.role)}}
              </template>

              <template slot="actions" slot-scope="data">
                <b-link class="badge badge-light" @click="editUser(data.item.id)">
                  <span class="lnr lnr-pencil"></span>
                  {{ $t('edit') }}
                </b-link>
                <b-link class="badge badge-light" @click="destroyUser(data.item.id)">
                  <span class="lnr lnr-trash"></span>
                </b-link>
              </template>
            </b-table>

            <b-modal id="modal-1" :title="$t('users')" @ok='submitForm' @close='resetModelInfo' @cancel='resetModelInfo' :cancelTitle="$t('cancel')">
    				  <div class="row">
      					<div class="col-md-6">
      					  <div class="input-group mb-3 mr-sm-2">
        						<div class="input-group-prepend">
        						  <div class="input-group-text"><span class="lnr lnr-user"></span></div>
        						</div>
        						<input type="text" class="form-control" :placeholder="$t('first_name')" v-model="user.first_name">
      					  </div>
      					</div>
      					<div class="col-md-6">
      					  <div class="input-group mb-3 mr-sm-2">
        						<div class="input-group-prepend">
        						  <div class="input-group-text"><span class="lnr lnr-envelope"></span></div>
        						</div>
        						<input type="text" class="form-control" :placeholder="$t('last_name')" v-model="user.last_name">
      					  </div>
      					</div>
      					<div class="col-md-6">
      					  <div class="input-group mb-3 mr-sm-2">
        						<div class="input-group-prepend">
        						  <div class="input-group-text"><span class="lnr lnr-user"></span></div>
        						</div>
        						<input type="text" class="form-control" :placeholder="$t('email')" v-model="user.email">
      					  </div>
      					</div>
      					<div class="col-md-6">
      					  <div class="form-group">
        						<b-form-select v-model="user.role" :options="roles"></b-form-select>
      					  </div>
      					</div>

                <div class="col-md-6">
      						<div class="custom-control custom-checkbox">
      						  <input type="checkbox" class="custom-control-input" v-model="user.active" id="customCheck2">
      						  <label class="custom-control-label" for="customCheck2">{{ $t('active') }}</label>
      						</div>
      					</div>

                <div class="col-md-6">
      					  <div class="form-group">
                    <b-form-checkbox
                      id="allow-notification"
                      v-model="user.recieve_notification"
                      value="true"
                      unchecked-value="false"
                    >
                      Recieve Notification
                    </b-form-checkbox>
                  </div>
                </div>

    				  </div>
            </b-modal>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import Navbar from '../../../components/navbar.vue'
  import SettingsSidebar from '../../../components/sidebar.vue'

  export default{
    components: {
      Navbar,
      SettingsSidebar
    },

    data: function(){
      return {
        data: [],

        user: {
          id: null,
          first_name: "",
          last_name: "",
          email: "",
          role: "",
          active: true
        },
        filter: null
      }
    },

    mounted: function(){
      axios.get("/api/settings/users.json")
      .then(response => {
        this.data = response.data
      })
    },

    methods: {
      submitForm: function(bvModalEvt){
        bvModalEvt.preventDefault()
        const vm = this
        if(this.user.id){
          axios.put("/api/settings/users/"+this.user.id+".json", {
            user: this.user,
            authenticity_token: this.csrf
          })
          .then(response => {
            this.$set(vm.data, this.findIndexOfUser(this.user.id), response.data);
            vm.$bvModal.hide("modal-1")
          })
          .catch(error => {

          })
        }
        else{
          axios.post("/api/settings/users.json", {
            user: this.user,
            authenticity_token: this.csrf
          })
          .then(response => {
            this.data.push(response.data)
            vm.$bvModal.hide("modal-1")
          })
          .catch(error => {

          })
        }
      },

      findIndexOfUser: function(user_id){
        return this.data.findIndex( u => u.id == user_id )
      },

      editUser: function(user_id){
        var item = this.data[this.findIndexOfUser(user_id)] || this.user
        this.user = Object.assign({}, (this.data[this.findIndexOfUser(user_id)] || this.user))
        this.$bvModal.show("modal-1")
      },

      resetModelInfo: function(){
        this.user = {
          id: null,
          first_name: "",
          last_name: "",
          email: "",
          role: "",
          active: true
        }
      },

      openNewFormModal: function(){
        this.resetModelInfo();
        this.$bvModal.show("modal-1")
      },

      destroyUser: function(user_id){
        axios.delete("/api/settings/users/"+user_id+".json?authenticity_token="+this.csrf)
        .then(response => {
          this.data.splice(this.findIndexOfUser(user_id), 1)
        })
        .catch(error => {

        })
      }
    },

    computed: {
      fields: function(){
        return [
          {
            key: "name",
            label: this.$t('name')
          },
          {
            key: "email",
            label: this.$t('email')
          },
          {
            key: "role",
            label: this.$t('role')
          },
          {
            key: "actions",
            label: this.$t('actions')
          }
        ]
      },

      roles: function(){
        return [
          {value: "", text: ""},
          {value: "Admin", text: 'Admin'},
          {value: "User", text: this.$t('user')}
        ]
      }
    }
  }
</script>
