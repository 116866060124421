<template>
  <div>
    <Navbar />
    <b-container fluid class="main" role="main">
      <b-row class="align-items-start insp-list">
        <SettingsSidebar selectedComponent="structure_capacity"/>
        <b-col md="10" class="offset-2">
          <b-card>
            <b-table :items="data" head-variant="light" responsive class="mt-4" :fields="fields">
              <template slot="capacity" slot-scope="data">
                <div class="form-group rfc mb-0">
                  <b-form-input v-model="data.item.capacity" type="number" class="form-control-sm"></b-form-input>
                  &nbsp; {{ $t('lb_sq_ft') }}
                </div>
              </template>
            </b-table>
            <b-row>
              <b-col md="12" class="text-center">
                <button type="button" class="btn btn-light">{{$t('cancel')}}</button>
						    <button type="button" class="btn btn-primary" @click="submitForm">&nbsp; {{$t('save')}} &nbsp;</button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import Navbar from '../../../components/navbar.vue'
  import SettingsSidebar from '../../../components/sidebar.vue'

  export default{
    components: {
      Navbar,
      SettingsSidebar
    },

    data: function(){
      return {
        data: []
      }
    },

    mounted: function(){
      axios.get("/api/settings/structure_capacities.json")
      .then(response => {
        this.data = response.data
      })
    },

    methods: {
      submitForm: function(bvModalEvt){
        bvModalEvt.preventDefault()
        const vm = this

        axios.post("/api/settings/structure_capacities/bulk_update.json", {
          data: this.data,
          authenticity_token: this.csrf
        })
        .then(response => {

        })
        .catch(error => {

        })
      }
    },

    computed: {
      fields: function(){
        return [
          {
            key: "province",
            label: this.$t("province")
          },
          {
            key: "capacity",
            label: this.$t("roof_structure_capacity")
          }
        ]
      }
    }
  }
</script>
