<template>
  <div>
    <Navbar />
    <b-container fluid class="main" role="main">
      <div class="row align-items-start insp-list">
			  <div class="col-md-12 mb-3">
  				<div class="card">
  				  <div class="card-body">
    					<form>
    					  <div class="form-row">
    						<div class="col-md-6 col-sm-6">
    						  <div class="search-box">
    								<span class="lnr lnr-magnifier"></span>
    								<a href="" class="close-srch"><span class="lnr lnr-cross"></span></a>
    								<input type="text" class="form-control" :placeholder="$t('search')" v-model="filter">
    						  </div>
    						</div>
    						<!-- <div class="col-md-2 col-sm-6">
    						  <select id="inputState" class="form-control" v-model="province_filter">
      							<option value="" selected>Province</option>
                    <option v-for="(province, province_key) in provinces" :value="province_key">{{province.name}}</option>
    						  </select>
    						</div>
    						<div class="col-md-2 col-sm-6">
      						<select id="inputState" class="form-control" v-model="region_filter">
                    <option value="" selected>Region</option>
                    <template v-for="(province, province_key) in provinces" :value="province_key">
                      <option v-for="region in province.regions" :value="region">
                        {{region}}
                      </option>
                    </template>
                  </select>
    						</div> -->
    						<div class="col-md-2 col-sm-6">
    						  <select id="inputState" class="form-control" v-model="status_filter">
    							<option selected="" value=''>{{$t("status")}}</option>
    							<option value="Safe">{{$t("safe")}}</option>
    							<option value="Alert">{{$t("alert")}}</option>
    							<option value="Emergency">{{$t("emergency")}}</option>
    						  </select>
    						</div>
    					  </div>
    					</form>

              <b-row align-h="end" class="mt-3">
                <b-col cols="4">
                  <b-row align-h="end">
                    <b-col cols="4"><b-button :pressed.sync="showMyBuildings" variant="outline-secondary" class="float-right">My buildings</b-button></b-col>
                    <b-col cols="4">
                      <b-button variant="primary" @click="openNewFormModal" class="float-right">
                        + {{ $t("new_building") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

    					<b-table :fields="fields" :items="tableItems" :filter="filter" head-variant="light" responsive class="mt-4" @filtered="onFiltered">
                <template slot="client_organization" slot-scope="data">
                  {{ (data.item.client || {}).organization }}
                </template>

                <template slot="weather_station_distance" slot-scope="data">
                  {{convertMeterToKm(data.item.weather_station_distance)}}
                </template>

                <template slot="last_weather_update" slot-scope="data">
                  {{ data.item.last_weather_update | formatDate}}
                </template>

                <template slot="roof_structure_capacity" slot-scope="data">
                  {{data.item.roof_structure_capacity}} {{ $t('lb_sq_ft') }}
                </template>

                <template slot="snow_accumulation" slot-scope="data">
                  <template v-if="data.item.snow_accumulation">
                    {{data.item.snow_accumulation | to_inches}}
                  </template>
                </template>

                <template slot="estimated_weight" slot-scope="data">
                  <template v-if="data.item.estimated_weight >= 0">
                    {{parseFloat(data.item.estimated_weight).toFixed(2)}} {{ $t('lb_sq_ft') }}
                  </template>
                </template>

                <template slot="actual_snow_accumulation" slot-scope="data">
                  <template v-if="data.item.snow_removal_done">
                    {{data.item.actual_snow_accumulation | to_inches }}
                  </template>
                  <template v-else>
                    <template v-if="data.item.snow_accumulation">
                      {{data.item.snow_accumulation | to_inches}}
                    </template>
                  </template>
                </template>

                <template slot="estimated_perceptive_accumulation_weight"  slot-scope="data">
                  <div class="d-flex">
                    <template v-if="data.item.estimated_perceptive_accumulation_weight >= 0">
                      <div>
                        {{parseFloat(data.item.estimated_perceptive_accumulation_weight).toFixed(2)}} {{ $t('lb_sq_ft') }}
                      </div>
                      <div>
                        <template v-if="data.item.is_precarious">
                          <div class="rounded-circle bg-danger p-1 sq-20 ml-1">
                          </div>
                        </template>
                        <template v-else>
                          <div class="rounded-circle bg-success p-1 sq-20 ml-1">
                          </div>
                        </template>
                      </div>
                    </template>
                  </div>
                </template>

                <template slot="status" slot-scope="data">
                  <template v-if="data.item.status=='Emergency'">
                    <b-badge variant="danger">
                      {{$t(data.item.status)}}
                    </b-badge>
                  </template>
                  <template v-else-if="data.item.status=='Warning'">
                    <b-badge variant="warning">
                      {{$t(data.item.status)}}
                    </b-badge>
                  </template>
                  <template v-else-if="data.item.status=='Safe'">
                    <b-badge variant="success">
                      {{$t(data.item.status)}}
                    </b-badge>
                  </template>
                </template>

                <!-- <template slot="status" slot-scope="data">

                </template> -->

                <template slot="action" slot-scope="data">
                  <b-link class="badge badge-light" @click="editBuilding(data.item.id)">
                    <span class="lnr lnr-pencil"></span>
                    {{ $t("edit") }}
                  </b-link>

                  <b-link class="badge badge-light" @click="editSnowData(data.item.id)">
                    <span class="lnr lnr-pencil"></span>
                    {{ $t("snow_data") }}
                  </b-link>

                  <span class="lnr lnr-alarm" v-if="data.item.snow_updated_manually"></span>
                </template>
              </b-table>

              <b-modal id="building-modal" :title="$t('building')" @shown="setAddress" @ok="submitBuildingForm" :cancelTitle="$t('cancel')">
                <b-form autocomplete="off">
                  <b-form-group
                    id="buiding-name-group"
                    :label="$t('building')"
                    label-for="buiding-name"
                    :description="$t('name_of_building')"
                  >
                    <b-form-input
                      id="buiding-name"
                      v-model="form_data.name"
                      type="text"
                      required
                      :placeholder="$t('building')"
                    ></b-form-input>

                    <b-form-invalid-feedback :state="(form_data_errors.name || []).length <= 0">
                      {{(form_data_errors.name || []).join(", ")}}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group id="building-address-group" :label="$t('address')" label-for="building-address">
                    <vue-google-autocomplete
                      ref="address"
                      id="building-address"
                      classname="form-control"
                      :placeholder="$t('start_typing')"
                      autocomplete="no_autocomplete_to_be_used"
                      v-on:placechanged="getAddressData"
                    >
                    </vue-google-autocomplete>

                    <b-form-invalid-feedback :state="(form_data_errors.address || []).length <= 0">
                      {{(form_data_errors.address || []).join(", ")}}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group id="building-region-group" :label="$t('region')" label-for="building-region">
                    <b-form-select
                      id="input-3"
                      v-model="form_data.region"
                      :options="regions"
                      required
                    ></b-form-select>

                    <b-form-invalid-feedback :state="(form_data_errors.region || []).length <= 0">
                      {{(form_data_errors.region || []).join(", ")}}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    id="roof-structure-capacity-group"
                    :label="$t('roof_structure_capacity')+' ('+ $t('lb_sq_ft')+')'"
                    label-for="roof-structure-capacity"
                    :description="$t('default_value_is', {val: 45} )"
                  >
                    <b-form-input
                      id="roof-structure-capacity"
                      v-model="form_data.roof_structure_capacity"
                      type="number"
                      :placeholder="$t('roof_structure_capacity')"
                    ></b-form-input>

                    <b-form-invalid-feedback :state="(form_data_errors.roof_structure_capacity || []).length <= 0">
                      {{(form_data_errors.roof_structure_capacity || []).join(", ")}}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group id="building-client-group" :label="$t('client')" label-for="building-region">
                    <b-form-select
                      id="building-client"
                      v-model="form_data.client_id"
                      :options="selectableClients"
                    ></b-form-select>

                    <b-form-invalid-feedback :state="(form_data_errors.client_id || []).length <= 0">
                      {{(form_data_errors.client_id || []).join(", ")}}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group id="building-project-manager-group" :label="$t('project_manager')" label-for="building-project-manager">
                    <b-form-select
                      id="building-project-manager"
                      v-model="buildingManager"
                      :options="selectableUsers"
                    ></b-form-select>

                    <b-form-invalid-feedback :state="(form_data_errors.manager_id || []).length <= 0">
                      {{(form_data_errors.manager_id || []).join(", ")}}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-form>
              </b-modal>

              <b-modal id="snow-modal" :title="$t('snow_data')" @ok="submitSnowDataForm" :cancelTitle="$t('cancel')">
                <b-form >
                  <table class="table table-sm modal-table">
          				  <tbody>
                      <tr v-if="snow_data_error.length > 0">
                        <td colspan="2">
                          <b-badge variant="danger">
                            {{snow_data_error}}
                          </b-badge>
                        </td>
                      </tr>
            					<tr>
            					  <td width="50%">{{ $t('ice') }}</td>
            					  <td>
            							<form>
            							  <div class="form-group rfc mb-0">
            								  <input type="number" class="form-control form-control-sm" v-model="form_data.ice"> %
            								</div>
            							</form>
            					  </td>
            					</tr>
            					<tr>
            					  <td width="50%"> {{$t('wet_snow')}} </td>
            					  <td>
            							<form>
            							  <div class="form-group rfc mb-0">
            								<input type="number" class="form-control form-control-sm" v-model="form_data.wet_snow"> %
            								</div>
            							</form>
            					  </td>
            					</tr>
            					<tr>
            					  <td width="50%">{{ $t('compact_snow') }}</td>
            					  <td>
            							<form>
            							  <div class="form-group rfc mb-0">
            								<input type="number" class="form-control form-control-sm" v-model="form_data.compact_snow"> %
            								</div>
            							</form>
            					  </td>
            					</tr>
            					<tr>
            					  <td width="50%"> {{ $t('light_snow') }} </td>
            					  <td>
            							<form>
            							  <div class="form-group rfc mb-0">
            								<input type="number" class="form-control form-control-sm" v-model="form_data.light_snow"> %
                            <input type="hidden" v-model="form_data.snow_updated_manually">
            								</div>
            							</form>
            					  </td>
            					</tr>
                      <tr>
                        <td width="50%"> {{ $t('did_you_do_snow_removal') }} </td>
                        <td>
                          <form>
            							  <div class="form-group rfc mb-0">
                              <b-form-checkbox
                                id="snow_removal_done_checkbox"
                                v-model="form_data.snow_removal_done"
                                value="true"
                                unchecked-value="false"
                              >
                              </b-form-checkbox>
            								</div>
            							</form>
                        </td>
                      </tr>
                      <template v-if="form_data.snow_removal_done == 'true' || form_data.snow_removal_done == true">
                        <tr>
                          <td width="50%">{{ $t('actual_height_of_snow') }}: </td>
                          <td>
              							<form>
              							  <div class="form-group rfc mb-0">
              								<input type="number" class="form-control form-control-sm" v-model="form_data.actual_snow_accumulation"> {{ $t('cm') }}
              								</div>
              							</form>
              					  </td>
                        </tr>
                      </template>
          				  </tbody>
          				</table>
                </b-form>
              </b-modal>
  				  </div>
  				</div>
			  </div><!--12 end-->
		  </div>
    </b-container>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Navbar from '../../components/navbar.vue'
import Provinces from '../../provinces.js'
export default {
  components: { Navbar, VueGoogleAutocomplete },

  data: function () {
    return {
      data: [],
      form_data: {
        name: [],
        address: [],
        client_id: [],
        region: [],
        roof_structure_capacity: 45
      },
      address: '',
      regions: [],
      clients: [],
      users: [],
      provinces: Provinces,
      filter: null,
      snow_data_error: "",
      form_data_errors: {},
      province_filter: "",
      region_filter: "",
      status_filter: null,
      showMyBuildings: false
    }
  },

  mounted: function(){
    this.getBuildings();

    axios.get("/api/settings/clients.json")
    .then(response => {
      this.clients = response.data
    })
    axios.get("/api/settings/users.json")
    .then(response => {
      this.users = response.data
    })
  },

  methods: {
    getBuildings: function(){
      axios.get("/api/buildings.json?followed=true&my_buildings="+this.showMyBuildings)
      .then(response => {
        this.data = response.data
      })
    },

    findIndexOfBuilding: function(building_id){
      return this.data.findIndex( u => u.id == building_id )
    },

    editBuilding: function(building_id){
      this.form_data = Object.assign({}, (this.data[this.findIndexOfBuilding(building_id)] || this.form_data))
      this.regions = ((Provinces[this.form_data.province] || {}).regions || [])
      this.$bvModal.show("building-modal")
    },

    submitSnowDataForm: function(bvModalEvt){
      if(this.snowDataSum == 100){
        this.form_data.snow_updated_manually = true
        this.submitBuildingForm(bvModalEvt, this.snowFormParams)
      }
      else{
        bvModalEvt.preventDefault()
        this.snow_data_error = "Sum of snow data percentage must be 100"
      }
    },

    submitBuildingForm: function(bvModalEvt, formdata=null){
      bvModalEvt.preventDefault()
      const vm = this
      if(this.form_data.id){
        axios.put("/api/buildings/"+this.form_data.id+".json", {
          building: (formdata || this.form_data),
          authenticity_token: this.csrf
        })
        .then(response => {
          this.$set(vm.data, this.findIndexOfBuilding(this.form_data.id), response.data);
          vm.$bvModal.hide("building-modal")
          vm.$bvModal.hide("snow-modal")
        })
        .catch(error => {
          this.form_data_errors = error.response.data
        })
      }
      else{
        axios.post("/api/buildings.json", {
          building: this.form_data,
          authenticity_token: this.csrf
        })
        .then(response => {
          this.data.push(response.data)
          vm.$bvModal.hide("building-modal")
        })
        .catch(error => {
          this.form_data_errors = error.response.data
        })
      }
    },

    getAddressData: function(addressData, placeResultData, id){
      this.form_data.address = placeResultData.formatted_address
      this.form_data.latitude = addressData.latitude
      this.form_data.longitude = addressData.longitude
      this.form_data.province = addressData.administrative_area_level_1
      this.regions = ((Provinces[this.form_data.province] || {}).regions || [])
    },

    openNewFormModal: function(){
      this.resetFormData()
      this.form_data.roof_structure_capacity = 45
      this.$bvModal.show("building-modal");
    },

    resetFormData: function(){
      this.form_data = {
        name: "",
        address: "",
        region: "",
      }
    },

    setAddress: function(){
      this.$refs.address.update(this.form_data.address)
    },

    editSnowData: function(building_id){
      this.snow_data_error = ""
      this.form_data = Object.assign({}, (this.data[this.findIndexOfBuilding(building_id)] || {}))
      this.$bvModal.show("snow-modal")
    }
  },

  watch: {
    showMyBuildings: function(val){
      this.getBuildings()
    }
  },

  computed: {
    snowFormParams: function(){
      return {
        snow_updated_manually: true,
        ice: this.form_data.ice,
        wet_snow: this.form_data.wet_snow,
        compact_snow: this.form_data.compact_snow,
        light_snow: this.form_data.light_snow,
        actual_snow_accumulation: this.form_data.actual_snow_accumulation,
        snow_removal_done: this.form_data.snow_removal_done
      }
    },

    buildingManager: {
      get: function(){
        var manager_id = (this.form_data.manager_id || this.current_user.id)
        this.form_data.manager_id = manager_id
        return this.form_data.manager_id
      },
      set: function(newValue){
        this.form_data.manager_id = newValue
      }
    },

    selectableClients: function(){
      var selectable_clients = []
      this.clients.forEach(function(cl){
        selectable_clients.push({value: cl.id, text: cl.organization})
      })
      return selectable_clients
    },

    selectableUsers: function(){
      var selectable_users = []
      this.users.forEach(function(user){
        selectable_users.push({value: user.id, text: `${user.first_name} ${user.last_name}`})
      })
      return selectable_users
    },

    snowDataSum: function(){
      return (parseInt(this.form_data.ice) + parseInt(this.form_data.compact_snow) + parseInt(this.form_data.light_snow) + parseInt(this.form_data.wet_snow))
    },

    tableItems: function(){
      var items = []
      var vm = this
      if((this.status_filter || "").length > 0){
        this.data.forEach(function(val){
          if(val.status == vm.status_filter){
            items.push(val)
          }
        })
      }
      else{
        items = this.data
      }

      return items
    },

    fields: function(){
      return [
        {
         key: "client_organization",
         label: this.$t("client"),
        },
        {
         key: "name",
         label: this.$t("building"),
        },
        {
         key: "address",
         label: this.$t("address"),
        },
        {
         key: "region",
         label: this.$t("region"),
        },
        {
         key: "last_weather_update",
         label: this.$t("last_weather_update")
        },
        {
         key: "weather_station_distance",
         label: this.$t("weather_station_km")
        },
        {
         key: "snow_accumulation",
         label: this.$t("on_ground_snow_accumulation")
        },
        {
         key: "actual_snow_accumulation",
         label: this.$t("estd_on_roof_accumulation")
        },
        {
          key: "estimated_perceptive_accumulation_weight",
          label: this.$t("estd_weight_in_days")
        },
        {
         key: "estimated_weight",
         label: this.$t("estimated_weight")
        },
        {
         key: "roof_structure_capacity",
         label: this.$t("roof_structure_capacity")
        },
        {
         key: "status",
         label: this.$t("status")
        },
        {
         key: "last_snow_removal_date",
         label: this.$t("last_snow_removal_date")
        },
        {
         key: "action",
         label: this.$t("actions")
        }
      ]
    }
  }
}
</script>

<style scoped>
  .sq-20 {
    width: 20px;
    height: 20px
  }
</style>
