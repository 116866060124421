import Vue from 'vue/dist/vue.esm';
import Vuex from "vuex";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    current_user: {},
    csrf: "",
    preferences: {},
    current_employee: {},
    current_locale: "",
    i18n: null
  },

  getters: {
    current_user: state => {
      return state.current_user
    },

    current_locale: state => {
      var locale = ""
      var name = "_current_locale=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }

      return (locale || state.current_locale)
    }
  },

  mutations: {
    set_refresh_data(state, data){
      state.csrf = data.csrf_token
      state.current_user = data.current_user
      state.preferences = data.preferences
      state.current_employee = data.current_employee
    },

    set_locale(state, payload){
      state.current_locale = payload.locale
      payload.i18n.locale = payload.locale
    },

    set_i18n(state, i18n){
      state.i18n = i18n
    }
  },

  actions: {
    refreshData(context, path){
      var url = '/auth/refresh.json'
      if(path.startsWith("/clients")){
        var url = '/auth/refresh_client.json'
      }
      axios
      .get(url)
      .then(response => {
        context.commit('set_refresh_data', response.data)
      })
      .catch(error => {
        if(path.startsWith("/clients")){
          window.location.href='/employees/sign_in'
        }
        else{
          window.location.href="/users/sign_in";
        }
      });
    },

    refreshLocale(context, payload){
      var url = "/api/settings/profiles/1"
      if(payload.is_client){
        url = "/api/clients/profiles/1"
      }
      axios
      .put(url, {profile: {locale: payload.locale}, authenticity_token: this.state.csrf})
      .then(response => {
        document.cookie = "_current_locale" + "=" + payload.locale + ";"
        // localStorage.setItem("current_locale", payload.locale)
        context.commit("set_locale", payload)
      })
      .catch(error => {

      })

    }
  }
});
