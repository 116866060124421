<template>
  <div>
    <Navbar />
    <b-container fluid class="main" role="main">
      <div class="row align-items-start insp-list">
			  <div class="col-md-12 mb-3">
  				<div class="card">
  				  <div class="card-body">
    					<form>
    					  <div class="form-row">
      						<div class="col-md-6 col-sm-6">
      						  <div class="search-box">
      								<span class="lnr lnr-magnifier"></span>
      								<a href="" class="close-srch"><span class="lnr lnr-cross"></span></a>
      								<input type="text" class="form-control" :placeholder="$t('search')" v-model="filter">
      						  </div>
      						</div>
                  <div class="col-md-2 col-sm-6">
      						  <select id="inputState" class="form-control">
      							<option selected="">{{$t("status")}}</option>
      							<option>{{$t("safe")}}</option>
      							<option>{{$t("alert")}}</option>
      							<option>{{$t("emergency")}}</option>
      						  </select>
      						</div>
    					  </div>
    					</form>

    					<b-table :fields="fields" :items="data" head-variant="light" :filter="filter" responsive class="mt-4">
                <template slot="weather_station_distance" slot-scope="data">
                  {{convertMeterToKm(data.item.weather_station_distance)}}
                </template>

                <template slot="roof_structure_capacity" slot-scope="data">
                  {{data.item.roof_structure_capacity}}{{ $t('lb_sq_ft') }}
                </template>

                <template slot="estimated_weight" slot-scope="data">
                  <template v-if="(data.item.estimated_weight || '').length > 0">
                    {{data.item.estimated_weight}} {{ $t('lb_sq_ft') }}
                  </template>
                </template>

                <template slot="status" slot-scope="data">
                  <template v-if="data.item.status=='Emergency'">
                    <b-badge variant="danger">
                      {{$t(data.item.status)}}
                    </b-badge>
                  </template>
                  <template v-else-if="data.item.status=='Alert'">
                    <b-badge variant="warning">
                      {{$t(data.item.status)}}
                    </b-badge>
                  </template>
                  <template v-else-if="data.item.status=='Safe'">
                    <b-badge variant="success">
                      {{$t(data.item.status)}}
                    </b-badge>
                  </template>
                </template>

                <!-- <template slot="status" slot-scope="data">

                </template> -->

                <template slot="action" slot-scope="data">
                  <b-link class="badge badge-light" @click="editBuilding(data.item.id)">
                    <span class="lnr lnr-pencil"></span>
                    {{$t('show')}}
                  </b-link>

                  <b-link class="badge badge-light" @click="editSnowData(data.item.id)">
                    <span class="lnr lnr-pencil"></span>
                    {{$t('snow_data')}}
                  </b-link>
                </template>
              </b-table>

              <b-modal id="building-modal" title="Building" @shown="setAddress" :cancelTitle="$t('cancel')">
                <b-form >
                  <b-form-group
                    id="buiding-name-group"
                    :label="$t('building')"
                    label-for="buiding-name"
                    :description="$t('name_of_building')"
                  >
                    <b-form-input
                      id="buiding-name"
                      v-model="form_data.name"
                      type="text"
                      disabled="true"
                      required
                      :placeholder="$t('building')"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group id="building-address-group" :label="$t('address')" label-for="building-address">
                    <vue-google-autocomplete
                      ref="address"
                      id="building-address"
                      classname="form-control"
                      :placeholder="$t('start_typing')"
                      disabled="true"
                      v-on:placechanged="getAddressData"
                    >
                    </vue-google-autocomplete>
                  </b-form-group>

                  <b-form-group id="building-region-group" :label="$t('region')" label-for="building-region">
                    <b-form-select
                      id="input-3"
                      v-model="form_data.region"
                      disabled="true"
                      :options="regions"
                      required
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    id="roof-structure-capacity-group"
                    :label="$t('roof_structure_capacity')+' ('+ $t('lb_sq_ft')+')'"
                    label-for="roof-structure-capacity"
                  >
                    <b-form-input
                      id="roof-structure-capacity"
                      v-model="form_data.roof_structure_capacity"
                      type="number"
                      disabled="true"
                    ></b-form-input>
                  </b-form-group>
                </b-form>
              </b-modal>

              <b-modal id="snow-modal" :title="$t('snow_data')" :cancelTitle="$t('cancel')">
                <b-form >
                  <table class="table table-sm modal-table">
          				  <tbody>
            					<tr>
            					  <td width="50%">
                          {{ $t("ice") }}
                        </td>
            					  <td>
            							<form>
            							  <div class="form-group rfc mb-0">
            								  <input type="number" class="form-control form-control-sm" disabled="true" v-model="form_data.ice"> %
            								</div>
            							</form>
            					  </td>
            					</tr>
            					<tr>
            					  <td width="50%">
                          {{ $t("wet_snow") }}
                        </td>
            					  <td>
            							<form>
            							  <div class="form-group rfc mb-0">
            								<input type="number" class="form-control form-control-sm" disabled="true" v-model="form_data.wet_snow"> %
            								</div>
            							</form>
            					  </td>
            					</tr>
            					<tr>
            					  <td width="50%">
                          {{ $t("compact_snow") }}
                        </td>
            					  <td>
            							<form>
            							  <div class="form-group rfc mb-0">
            								<input type="number" class="form-control form-control-sm" disabled="true" v-model="form_data.compact_snow"> %
            								</div>
            							</form>
            					  </td>
            					</tr>
            					<tr>
            					  <td width="50%">
                          {{ $t("light_snow") }}
                        </td>
            					  <td>
            							<form>
            							  <div class="form-group rfc mb-0">
            								<input type="number" class="form-control form-control-sm" disabled="true" v-model="form_data.light_snow"> %
            								</div>
            							</form>
            					  </td>
            					</tr>
          				  </tbody>
          				</table>
                </b-form>
              </b-modal>
  				  </div>
  				</div>
			  </div><!--12 end-->
		  </div>
    </b-container>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Navbar from '../../../components/client_navbar.vue'
import Provinces from '../../../provinces.js'
export default {
  components: { Navbar, VueGoogleAutocomplete },

  data: function () {
    return {
      data: [],

      form_data: {

      },
      address: '',
      regions: [],
      filter: null,
      provinces: Provinces
    }
  },

  mounted: function(){
    axios.get(`/api/clients/${this.$route.params.client_id}/buildings.json`)
    .then(response => {
      this.data = response.data
    })
  },

  methods: {
    findIndexOfBuilding: function(building_id){
      return this.data.findIndex( u => u.id == building_id )
    },

    editBuilding: function(building_id){
      this.form_data = Object.assign({}, (this.data[this.findIndexOfBuilding(building_id)] || this.form_data))
      this.regions = ((Provinces[this.form_data.province] || {}).regions || [])
      this.$bvModal.show("building-modal")
    },


    getAddressData: function(addressData, placeResultData, id){
      this.form_data.address = placeResultData.formatted_address
      this.form_data.latitude = addressData.latitude
      this.form_data.longitude = addressData.longitude
      this.form_data.province = addressData.administrative_area_level_1
      this.regions = ((Provinces[this.form_data.province] || {}).regions || [])
    },

    openNewFormModal: function(){
      this.form_data = {}
      this.$bvModal.show("building-modal");
    },

    resetFormData: function(){
      this.form_data = {}
    },

    setAddress: function(){
      this.$refs.address.update(this.form_data.address)
    },

    editSnowData: function(building_id){
      this.form_data = Object.assign({}, (this.data[this.findIndexOfBuilding(building_id)] || {}))
      this.$bvModal.show("snow-modal")
    }
  },

  computed: {
    fields: function(){
      return [
        {
         key: "name",
         label: this.$t("building"),
        },
        {
         key: "address",
         label: this.$t("address"),
        },
        {
         key: "region",
         label: this.$t("region"),
        },
        {
         key: "weather_station_distance",
         label: this.$t("weather_station_km")
        },
        {
         key: "snow_accumulation",
         label: this.$t("snow_accumulation_cm")
        },
        {
         key: "estimated_weight",
         label: this.$t("estimated_weight")
        },
        {
         key: "roof_structure_capacity",
         label: this.$t("roof_structure_capacity")
        },
        {
         key: "status",
         label: this.$t("status")
        },
        {
         key: "action",
         label: this.$t("actions")
        }
      ]
    }
  }
}
</script>
